import React from "react";
import { Card } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo"
import NeedAQuote from "../components/need-a-quote";
import computersBg from "../images/backrounds/189-scaled.jpg";
import supportIcon from "../icons/support-2.svg";
import teamWorkIcon from "../icons/teamwork.svg";
import cloudHostingIcon from "../icons/cloud-based-hosting.svg";
import cloudArqIcon from "../icons/cloud-based-architechture.svg";
import scheduledIcon from "../icons/scheduled-maintenance.svg";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "../components/cta";

const cardOptions = [
  {
    title: "Support Post Project",
    text: "The fun doesn’t stop when the project goes live. We offer post-project support such as hosting, scheduled maintenance and updates to the front and back-end systems, and monitoring to ensure your project is up and running continuously. Or, if your in-house team wants to take over from here, we can provide guidance and recommendations on making that transition as seamless as possible.",
    icon: supportIcon,
    alt: "Suport post project",
  },
  {
    title: "Guidance for professional services platforms",
    text: "There are thousands of different platforms available for businesses to use, with more being created all the time. 729 Solutions keeps up with changing and evolving technology and can help you determine what the right platform is for your unique business needs.",
    icon: teamWorkIcon,
    alt: "Guidance For professiona Services",
  },
  {
    title: "Cloud based architecture hosting",
    text: "There are many perks to using a cloud-based architecture hosting solution, and our team at 729 Solutions can help make sure you’re taking advantage of all of the benefits with a customized plan that is right for your business.",
    icon: cloudHostingIcon,
    alt: "Cloud based hosting",
  },
  {
    title: "Cloud based architecture maintenance",
    text: "Our services for cloud-based architecture don’t stop with hosting but extend into maintenance and operations as well. Our team can maintain your cloud architecture, keeping your systems up, running, and secure.",
    icon: cloudArqIcon,
    alt: "Cloud based maintenance",
  },
  {
    title: "Scheduled wordpress maintenance",
    text: "Our team at 729 Solutions is well versed in WordPress maintenance and support. We can handle anything from adding new website features to installing updates and patches, for websites that are big and small alike.",
    icon: scheduledIcon,
    alt: "Scheduled Maintenance",
  },
];

const staticImages = [
  <StaticImage src="../images/tools/html5.png" />,
  <StaticImage src="../images/tools/css3.png" />,
  <StaticImage src="../images/tools/js.png" />,
  <StaticImage src="../images/tools/bootstrap.png" />,
  <StaticImage src="../images/tools/jquery.png" />,
  <StaticImage src="../images/tools/react.png" />,
  <StaticImage src="../images/tools/ruby.png" />,
  <StaticImage src="../images/tools/php.png" />,
  <StaticImage src="../images/tools/java.png" />,
  <StaticImage src="../images/tools/dotnet.png" />,
  <StaticImage src="../images/tools/csharp.png" />,
  <StaticImage src="../images/tools/android.png" />,
  <StaticImage src="../images/tools/asp.png" />,
  <StaticImage src="../images/tools/groovy.png" />,
  <StaticImage src="../images/tools/ios.png" />,
  <StaticImage src="../images/tools/swift.png" />,
  <StaticImage src="../images/tools/objc.png" />,
  <StaticImage src="../images/tools/rails.png" />,
  <StaticImage src="../images/tools/wordpress.png" />,
  <StaticImage src="../images/tools/azure.png" />,
  <StaticImage src="../images/tools/node.png" />,
  <StaticImage src="../images/tools/ext.png" />,
  <StaticImage src="../images/tools/drupal.png" />,
  <StaticImage src="../images/tools/aws.png" />,
  <StaticImage src="../images/tools/sql.png" />,
  <StaticImage src="../images/tools/postgresql.png" />,
  <StaticImage src="../images/tools/oracle.png" />,
];

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Maintenance and Operations"
        description="729 Solutions can handle all of your maintenance and operations needs - from simple updates to complex operations - let us make it easy for you."
      />
      <section>
        <div
          className="hero mb-5"
          style={{
            backgroundImage: `url(${computersBg})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">Maintenance & Operations</h1>
            <p className="text-white">
              This service is designed to provide ongoing support to our
              client’s projects. Whenever you have an issue, question, or want
              to have a new feature implemented on your project, just reach out
              to our team and we’ll figure out the best path forward to solve
              your problem. You can receive 24/7 monitoring of your system or
              guidance and support on your software or service. We’ll work with
              you to find the perfect service level agreement for your needs.
            </p>
            <CTA
              href="/contact-us/"
              text="Tell Us About Your Project"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container container-fluid">
          <div className="row">
            <div className="col">
              <h2>Maintenance and Operations at a Glance</h2>
            </div>
          </div>
          <div className="row">
            {cardOptions.map((cardOption) => {
              const { icon: Icon } = cardOption;
              return (
                <div className="col-12 col-md-6 col-lg-4">
                  <Card className="card custom-cards card-border mb-5 py-4">
                    <Card.Body>
                      <Card.Title as="h3">{cardOption.title}</Card.Title>
                      <Card.Text>{cardOption.text}</Card.Text>
                    </Card.Body>
                    <Icon
                      width={140}
                      height={140}
                      className="card-img"
                      alt={cardOption.alt}
                    />
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <CTA
              href="/contact-us/"
              text="How can we help?"
              className="btn btn-primary"
            />
          </div>
        </div>
      </section>
      <section className="py-5 my-5">
        <div className="container my-5">
          <div className="row">
            <div className="col">
              <h2>Some of the tools we use</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {staticImages.map((image) => (
              <div className="col-6 col-lg-2">
                <div className="p-4">{image}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <NeedAQuote
        dark
        title="We're Ready To Help"
        text="729 Solutions knows that your operation’s needs don’t stop when a project is finished. It’s important to stay on top of any ongoing maintenance or operation needs to keep your systems updated and secure. From cloud hosting and maintenance to WordPress website updates, our team can keep your business up and running."
        buttonText="Let's talk"
      />
    </Layout>
  );
};

export default Index;
